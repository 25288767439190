<template>
  <b-modal id="modal-add-problem-classification" size="md" no-close-on-backdrop>
    <!-- custom modal title -->
    <template #modal-title>
      <div class="d-flex align-items-center">
        <feather-icon size="20" icon="PlusIcon" class="mr-05" />
        <span class="fs-18">Tambah Klasifikasi Baru</span>
      </div>
    </template>
    <!-- form container -->
    <loader-component v-if="is_loading" />
    <b-card no-body class="mb-0">
      <b-card-body>
        <validation-observer ref="form_data">
          <b-form>
            <!-- Jenis Klasifikasi -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <label for="name">
                  Jenis Klasifikasi <span class="text-danger">*</span>
                </label>
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="FolderIcon" />
                  </b-input-group-prepend>

                  <b-form-input
                    v-model="problem_classification_data.name"
                    id="name"
                    placeholder="Masukkan Jenis Klasifikasi"
                  />
                </b-input-group>
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
                <small
                  v-if="is_error"
                  class="text-danger"
                  style="font-size: 10px"
                >
                  {{ error_message || "" }}
                </small>
              </validation-provider>
            </b-form-group>
            <!-- Deskripsi -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Deskripsi"
                rules="required"
              >
                <label for="Deskripsi">
                  Deskripsi <span class="text-danger">*</span>
                </label>
                <b-form-textarea
                  v-model="problem_classification_data.description"
                  id="Deskripsi"
                  placeholder="Masukkan Deskripsi"
                  rows="7"
                />
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
    <!-- custom modal footer -->
    <template #modal-footer>
      <!-- button batal -->
      <b-button
        variant="outline-danger"
        size="sm"
        class="float-right mr-05"
        @click="cancelAddProblemClassification"
      >
        Batal
      </b-button>
      <!-- button simpan -->
      <b-button
        variant="outline-info"
        size="sm"
        class="float-right"
        @click.prevent="addNewProblemClassification"
      >
        Simpan
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal,
  VBModal,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BAlert,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import useJwt from "@/auth/jwt/useJwt";
import floating_loader from "@/views/components/floatingLoader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "addUser",
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BAlert,
    BFormTextarea,
    "loader-component": floating_loader,
  },
  data() {
    return {
      is_loading: false,
      is_error: false,
      error_message: "",
      problem_classification_data: {
        name: null,
        description: null,
      },
      //   validation
      required,
    };
  },
  methods: {
    addNewProblemClassification() {
      this.$refs.form_data.validate().then((success) => {
        if (success) {
          this.is_loading = true;
          this.is_error = false;
          let api = process.env.VUE_APP_API_URL + "problem-classification/add";
          let params = {
            name: this.problem_classification_data.name,
            description: this.problem_classification_data.description,
          };
          useJwt.axiosIns
            .post(api, params)
            .then(() => {
              this.problem_classification_data = {
                name: null,
                description: null,
              };
              this.is_error = false;
              this.$bvModal.hide("modal-add-problem-classification");
              this.$parent.getData();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Berhasil",
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Klasifikasi Telah Ditambahkan!`,
                },
              });
            })
            .catch((err) => {
              if (err?.response?.data?.detail?.message) {
                this.error_message = err.response.data.detail.message;
              } else {
                this.error_message = "Terjadi Kesalahan Pada Sistem!";
              }
              this.is_error = true;
            })
            .finally(() => {
              this.is_loading = false;
            });
        }
      });
    },
    cancelAddProblemClassification() {
      (this.problem_classification_data = {
        name: null,
        description: null,
      }),
        this.$bvModal.hide("modal-add-problem-classification");
    },
  },
  directives: {
    "b-modal": VBModal,
  },
};
</script>
<style>
.modal-title {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 20px !important;
  font-weight: bold;
}
</style>
