<template>
  <b-modal
    id="modal-edit-problem-classification"
    size="md"
    no-close-on-backdrop
  >
    <!-- custom modal title -->
    <template #modal-title>
      <div class="d-flex align-items-center">
        <feather-icon size="20" icon="EditIcon" class="mr-05" />
        <span class="fs-18">Edit Klasifikasi</span>
      </div>
    </template>
    <!-- form container -->
    <loader-component v-if="is_loading" />
    <b-card no-body class="mb-0">
      <b-card-body>
        <validation-observer ref="form_data">
          <b-form>
            <!-- Jenis Klasifikasi -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Jenis Klasifikasi"
                rules="required"
              >
                <label for="name">
                  Jenis Klasifikasi <span class="text-danger">*</span>
                </label>
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="FolderIcon" />
                  </b-input-group-prepend>

                  <b-form-input
                    v-model="data.name"
                    id="name"
                    placeholder="Masukkan Jenis Klasifikasi"
                  />
                </b-input-group>
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
                <small
                  v-if="is_error"
                  class="text-danger"
                  style="font-size: 10px"
                >
                  {{ error_message || "" }}
                </small>
              </validation-provider>
            </b-form-group>
            <!-- Deskripsi -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Deskripsi"
                rules="required"
              >
                <label for="Deskripsi">
                  Deskripsi <span class="text-danger">*</span>
                </label>
                <b-form-textarea
                  v-model="data.description"
                  id="Deskripsi"
                  placeholder="Masukkan Deskripsi"
                  rows="7"
                />
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
    <!-- custom modal footer -->
    <template #modal-footer>
      <!-- button batal -->
      <b-button
        variant="outline-danger"
        size="sm"
        class="float-right mr-05"
        @click="cancelUpdateUser"
      >
        Batal
      </b-button>
      <!-- button simpan -->
      <b-button
        variant="outline-info"
        size="sm"
        class="float-right"
        @click.prevent="updateUser"
      >
        Simpan
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal,
  VBModal,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";
import floating_loader from "@/views/components/floatingLoader.vue";

export default {
  name: "updateUser",
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BFormTextarea,
    "loader-component": floating_loader,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      is_loading: false,
      is_error: false,
      error_message: "",
      //   validation
      required,
    };
  },
  methods: {
    updateUser() {
      this.$refs.form_data.validate().then((success) => {
        if (success) {
          this.is_loading = true;
          this.is_error = false;
          let api =
            process.env.VUE_APP_API_URL +
            "problem-classification/update/" +
            this.data._id;
          let params = {
            name: this.data.name,
            description: this.data.description,
          };
          useJwt.axiosIns
            .put(api, params)
            .then(() => {
              this.is_error = false;
              this.$bvModal.hide("modal-edit-problem-classification");
              this.$parent.getData();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Berhasil",
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Klasifikasi Telah Diubah!`,
                },
              });
            })
            .catch((err) => {
              if (err?.response?.data?.detail?.message) {
                this.error_message = err.response.data.detail.message;
              } else {
                this.error_message = "Terjadi Kesalahan Pada Sistem!";
              }
              this.is_error = true;
            })
            .finally(() => {
              this.is_loading = false;
            });
        }
      });
    },
    cancelUpdateUser() {
      this.$bvModal.hide("modal-edit-problem-classification");
    },
  },
  directives: {
    "b-modal": VBModal,
  },
};
</script>
<style>
.modal-title {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 20px !important;
  font-weight: bold;
}
</style>
